import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import qa from "./faq.json";

export default function BasicAccordion() {
  return (
    <div>
      {/* <Typography variant="h3" gutterBottom>
        Поширені питання
      </Typography> */}
      {qa.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography fontWeight={600}>{item.q}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography dangerouslySetInnerHTML={{__html: item.a}}></Typography>
          </AccordionDetails>
        </Accordion>
      ))}

    </div>
  );
}
