import * as React from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";

export default function MinHeightTextarea({ showTZText }) {
  const StyledTextarea = styled("textarea")(({ theme }) => {
    return `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 4px;
    color: "#24292f;
    background: #fff;
    border: 1px solid #d0d7de;
    box-shadow: 0px 2px 2px #f6f8fa;

    &:hover {
      border-color: #3399FF;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #b6daff;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `;
  });

  return (
    <>
      <FormLabel component="legend">Додаткова Інформація</FormLabel>
      <textarea className="styled-text-area" name="comment"></textarea>
      {showTZText && (
        <Typography color={"#000000a3"}>
          Якщо у вас є правки до сайту, або ви хочете щось додати - створіть ТЗ
          і додайте посилання на нього.
          <a
            target="_blank"
            href="https://telegra.ph/YAk-stvoriti-TZ-na-google-drive-10-16"
          >
            {" "}
            Інструкція по створенню ТЗ
          </a>
        </Typography>
      )}
    </>
  );
}
