import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ToastContainer } from "react-toastify";
import BasicAccordion from "./Questions";

export default function QuestionsAnswers({ usd }) {
  return (
    <>
      <Container sx={{ mt: 2 }}>
        <Typography
          component="h1"
          variant="h4"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Поширені запитання
        </Typography>

        <Grid
          container
          spacing={{
            md: 4,
            xs: 0,
          }}
        >
          <Grid xs={12}>
            <CssBaseline />
            <Box>
              <BasicAccordion />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <ToastContainer />
    </>
  );
}
